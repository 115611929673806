@import "react-alice-carousel/lib/alice-carousel.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GoldenHopes';
  src: url('./fonts/golden_hopes/GoldenHopes.otf') format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Josefin Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.golden-hopes {
  font-family: 'GoldenHopes', sans-serif;
}

.golden-hopes-big-title {
  font-family: 'GoldenHopes', sans-serif;
  font-size: 2.5rem;
}

.font-medium {
  font-weight: 500;
  font-family: var(--dm-sans-font-family);
}

.josefin-sans-thin {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100; /* Poids "thin" */
  font-style: normal;
}

.josefin-sans-extra-light {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200; /* Poids "extra-light" */
  font-style: normal;
}

.josefin-sans-light {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300; /* Poids "light" */
  font-style: normal;
}

.josefin-sans-regular {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400; /* Poids "regular" */
  font-style: normal;
}

.josefin-sans-medium {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500; /* Poids "medium" */
  font-style: normal;
}

.josefin-sans-semibold {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600; /* Poids "semi-bold" */
  font-style: normal;
}

.josefin-sans-bold {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700; /* Poids "bold" */
  font-style: normal;
}

:root {
  --dm-sans-font-family: "DM Sans", sans-serif;
}

.dm-sans-100 {
  font-family: var(--dm-sans-font-family);
  font-weight: 100;
  font-style: normal;
}

.dm-sans-200 {
  font-family: var(--dm-sans-font-family);
  font-weight: 200;
  font-style: normal;
}

.dm-sans-300 {
  font-family: var(--dm-sans-font-family);
  font-weight: 300;
  font-style: normal;
}

.dm-sans-400 {
  font-family: var(--dm-sans-font-family);
  font-weight: 400;
  font-style: normal;
}

.dm-sans-500 {
  font-family: var(--dm-sans-font-family);
  font-weight: 500;
  font-style: normal;
}

.dm-sans-600 {
  font-family: var(--dm-sans-font-family);
  font-weight: 600;
  font-style: normal;
}

.dm-sans-700 {
  font-family: var(--dm-sans-font-family);
  font-weight: 700;
  font-style: normal;
}


.overlay::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
